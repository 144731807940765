'use strict';

/*******************************************************************************************/
class UxrGpListFrame_file extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listDI: null,
		};
		
		this.key = IdGenerator.produce('UxrGpList_');
		this.isFocused = undefined;
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
		this.onNewEntity = this.onNewEntity.bind(this);
		this.onMouseOver = this.onMouseOver.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		if (this.state.listDI === null) {
			return (
				<div className="loadingCircle">
				</div>
			);
		}
		
		let model = this.state.listDI.node.data.model;
		let ListType = model.visuality_glassList.reactTypeInner[0];
		let listProps = {
			'listId': this.state.listDI.node.id,
			'onEditWish': this.props.onEditWish,
		};
		
		return (
			<div 
				className="uiPanel ui_entityList glass UxrGpList" 
				onMouseOver={this.onMouseOver} 
				onMouseMove={this.onMouseMove} 
				ref={this.onJQueryMount}
			>
				<div className="uiHeadline ">
					{false && <div className="flowLeft uiIcon">{model.visuality_glassList.icon}</div>}
					<form>
				        <input type="file" className="dropzone flowRight uiCreate uiActButton" />
					</form>
					<div className="flowLeft uiTitle">{model.visuality_glassList.title}</div>
					<div className="flowClear"></div>
				</div>
				
				<div className="uxrGpList_floatingControls">
					<input type="text" placeholder="filter" className="flowLeft uiFilter " />
					<div className="flowClear" />
				</div>
				
				<ListType {...listProps} />
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		if (node === null) {
			 return;
		}
		
		let o = this;
        let url = LOCATOR.urlServer+ '/altoRoute.restricted/dbs/createFileNode';
		
		this.jQ = jQuery(node);
		this.jQ_control = this.jQ.find('.dropzone');
		this.props.sharedContext.jQ = this.jQ;
		this.props.sharedContext.onJQueryMount();
		
        this.dropzone = new Dropzone(
                this.jQ_control[0],
                {
                    //'hiddenInputContainer': jQuery('#fileUploadContainer')[0],
                    //'previewsContainer': this.jQ_control[0],
                    'url': url,
                    'params': {
                        'entityType': o.state.listDI.node.id,
                    },
                    'maxFilesize': 50,
                    'uploadMultiple': true,
                    'clickable': true,
                    'autoProcessQueue': true,
                    'addRemoveLinks': true,
                    'sendingmultiple': function(listOfFiles, xhr, formData) {
                        console.log('sendingmultiple', formData);
                        let updateOk = E.updateController.attemptNewUpdate('upload');
                        if (!updateOk) {
                            throw new Error('attempting to terminate dropzone\'s pipeline');
                        }
                    },
                    'uploadprogress': function(file, perc) {
                        //TODO
                    },
                    'successmultiple': function(listOfFiles, xhrResponse) {
                        let xhr = {
                            'responseJSON': xhrResponse,
                        };
                        
                        new Promise(function(resolve, reject) {
                            PromiseAjaxData.evaluateResponse(xhr, 'success', url, null, resolve, reject)
                        })
                        .then(function(xhrResponse) {
                            E.dataManager.server_absorbUploadResponse(xhrResponse);
                            return Promise.all([xhrResponse, E.intermediateLayer.dataImage.clone(o.state.listDI)]);
                        })
                        .then(function(cont) {
                            let xhrResponse = cont[0];
                            let newListDI = cont[1];
                            
                            _.forEach(xhrResponse.reason, function(eachNodeId) {
                                newListDI.values.data.items.addCached(eachNodeId);
                            });
                            
                            return E.intermediateLayer.dataImage
                                .store(newListDI)
                                ;
                        })
                        .then(function(newListDI) {
                            E.updateController.updateMessages.push({'nodeId': newListDI.node.id, 'dataImage': newListDI});
                            E.updateController.finalizeUpdate();
                        })
                        .catch(function() {
                            E.updateController.finalizeUpdate()
                            UI.guiOverlay.notifyUser_processBlocked('Something complicated just happened! The error has been logged.');
                            Raven.captureException('upload file Promise failed', arguments);
                        })
                        ;
                    },
                    'error': function(file, errorMessage, xhr) {
                        console.log('error', arguments);
                        UI.guiOverlay.notifyUser_processBlocked(errorMessage+ 'This error has been logged');
                        Raven.captureException(errorMessage, xhr);
                        //dropProgressNode[0].style.backgroundColor = 'rgba(255, 64, 32, 1)';
                    }
                    
                }
            );
	}
	
	/*---------------------------------------------------------------------*/
	componentDidMount() {
		E.updateController.registerListener(this.key, this.updateListener.bind(this));
		this.updateState();
	}
	
	/*---------------------------------------------------------------------*/
	componentWillUnmount() {
		E.updateController.removeListeners(this.key);
		this.props.sharedContext.componentWillUnmount();
	}
	
	/*---------------------------------------------------------------------*/
	componentDidUpdate() {
	}
	
	/*---------------------------------------------------------------------*/
	onNewEntity(event) {
		if (!E.updateController.attemptNewUpdate()) {
			//TODO: gui notify
			return;
		}
		
		let o = this;
		
		E.intermediateLayer.dataImage.create(
			this.state.listDI.node.data.itemTypeDescriptor,
			providerOfNodeData,
			providerOfValuesData,
			establishDataConsistency
		)
		.then(function(dataImage) {
			//TODO: gui notify
			E.updateController.finalizeUpdate();
			o.props.onEditWish(dataImage.node.id);
		})
		;
		
		return;
		
		function providerOfNodeData() {
			return { //json data mockup for node
				'associations': {
					'entityType': DataEntityAssociation().setDataImage(o.state.listDI).toJSONable()
				},
			};
		}
		function providerOfValuesData() {
			return {
				'userInput': o.state.listDI.node.data.model.bottomReadout
			};
		}
		function establishDataConsistency(newItemDI) {
			E.updateController.updateMessages.push({'nodeId': newItemDI.node.id, 'dataImage': newItemDI});
			
			return E.intermediateLayer.dataImage
				.clone(o.state.listDI)
				.then(function(newListDI) {
					newListDI.values.data.items.addDataImage(newItemDI);
					return E.intermediateLayer.dataImage
						.store(newListDI)
						;
				})
				.then(function(newListDI) {
					E.updateController.updateMessages.push({'nodeId': newListDI.node.id, 'dataImage': newListDI});
				})
				;
		}
	}
	
	/*---------------------------------------------------------------------*/
	updateState() {
		let o = this;
		
		E.localCache.dataImage.get(o.props.nodeId).then(function(listDI) {
			o.setState({
				'listDI': listDI,
			});
		});
	}
	
	/*---------------------------------------------------------------------*/
	updateListener(messages) {
		let relevantNodes = {};
		[this.props.nodeId].forEach(function(value) {
			relevantNodes[value] = value;
		});
		
		messages = messages.filter(function(message) {
			return (message.nodeId in relevantNodes);
		});
		
		if (messages.length) {
			this.updateState();
		}
	}
	
	/*---------------------------------------------------------------------*/
	onMouseOver(event) {
	}
	
	/*---------------------------------------------------------------------*/
	onMouseMove(event) {
	}
	
	/*---------------------------------------------------------------------*
	onFocusChange() {
		if (this.props.focused === this.focused) {
			return;
		}
		
		this.focused = this.props.focused;
		
		if (this.focused) {
			if (o.html.container.hasClass('ui_entityListFocused')) {
				return;
			}
			
			let delayedApply = setTimeout(function() {
				o.uiOver.onUiFocus(o);
				o.html.container
					.off('mouseleave')
					.addClass('ui_entityListFocused')
					;
				setTimeout(function() {
					o.html.container
						.removeClass('ui_entityListProcess')
						.on('mouseleave', onFocusLeave)
						;
				}, 600);
			}, 100);
			
			o.html.container
				.off('mouseleave')
				.on('mouseleave', function() { 
					clearTimeout(delayedApply);
					o.html.container
						.off('mouseleave')
						.on('mouseleave', onFocusLeave)
						.removeClass('ui_entityListProcess')
						.find('.MVT_listMaster')
							.addClass('MVT_listCompressed')
							.end()
						;
				})
				.addClass('ui_entityListProcess')
				.find('.MVT_listMaster')
					.removeClass('MVT_listCompressed')
					.end()
				;
			
		} else {
			o.html.container
				.off('mouseenter')
				.removeClass('ui_entityListFocused')
				.addClass('ui_entityListProcess')
				.find('.MVT_listMaster')
					.addClass('MVT_listCompressed')
					.end()
				;
			
			setTimeout(function() {
				o.html.container
					.on('mouseenter', onMouseEnter)
					.removeClass('ui_entityListProcess')
					;
			}, 600);
		}
	}
	
	/*---------------------------------------------------------------------*/
} //class
